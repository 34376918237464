@font-face {
  font-family: Montserrat;
  src: url("../fonts/montserrat-v25-latin-regular.ttf");
  font-display: swap;
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100vh;
  font-family: "Montserrat", serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  /*body {*/
  /*  color: white;*/
  /*  background: black;*/
  /*}*/
}


.MuiPaper-root {
  border-radius: 7px;
}

.MuiSlider-root:hover {
  color: #2e8b57;
}

.full-width {
  width: 100vh;
}

.pswp img {
  max-width: none;
  object-fit: contain;
}

.map-container {
  width: 100%;
  height: 100vh;
}

#nprogress .bar {
  background: #ec407a !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #ec407a, 0 0 5px #ec407a !important;
}

/*global*/
/*div { border: lightgrey solid 0.5px }*/
